<script setup lang="ts"></script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02943 13.9706 0 9 0C4.02942 0 0 4.02943 0 9C0 13.9706 4.02942 18 9 18ZM8 5C8 4.44772 8.44775 4 9 4C9.55225 4 10 4.44772 10 5V8H13C13.5522 8 14 8.44772 14 9C14 9.55228 13.5522 10 13 10H10V13C10 13.5523 9.55225 14 9 14C8.44775 14 8 13.5523 8 13V10H5C4.44775 10 4 9.55228 4 9C4 8.44772 4.44775 8 5 8H8V5Z"
            fill="currentColor"
        />
    </svg>
</template>
